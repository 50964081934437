<template>
  <div class="title-subtitle-text-questions-and-answers pt-50 pb-50 animated fadeIn">
    <app-section>
      <div class="container mx-auto mt-32 mb-70">
        <h1 data-test="faqs-title" class="title pb-32 text-46 text-grey-900 font-bbva font-medium">
          {{ page.title }}
        </h1>
        <h2 class="title pb-32 text-30 text-grey-900">{{ page.subtitle }}</h2>
        <p class="text-20">{{ page.text }}</p>
      </div>
    </app-section>
    <app-section class="bg-white">
      <div class="container mx-auto">
        <questions-answers-list :items="page.faqs" />
      </div>
    </app-section>
  </div>
</template>
<script>
import QuestionsAnswersList from '@/components/QuestionsAnswersList'
import AppSection from '@/components/AppSection'
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'TitleSubtitleTextQuestionsAndAnswers',
  components: {
    QuestionsAnswersList,
    AppSection,
  },
  mixins: [BaseTemplate],
}
</script>
