import Dropdown from './dropdown/Dropdown.vue'
import Home from './home/Home'
import Landing from './landing/Landing'
import TextDocumentsColumns from './textDocumentsColumns/TextDocumentsColumns.vue'
import TextsDocuments from './textsDocuments/TextsDocuments.vue'
import TitleColumnsDocuments from './titleColumnsDocuments/TitleColumnsDocuments'
import TitleSubtitleExternalLink from './titleSubtitleExternalLink/TitleSubtitleExternalLink.vue'
import TitleSubtitleTextLink from './titleSubtitleTextLink/TitleSubtitleTextLink'
import TitleSubtitleTextQuestionsAndAnswers from './titleSubtitleTextQuestionsAndAnswers/TitleSubtitleTextQuestionsAndAnswers.vue'
import TitleTextDocuments from './titleTextDocuments/TitleTextDocuments.vue'
import TitleText from './titleText/TitleText.vue'

export default {
  Dropdown,
  Home,
  Landing,
  TextDocumentsColumns,
  TextsDocuments,
  TitleColumnsDocuments,
  TitleSubtitleExternalLink,
  TitleSubtitleTextQuestionsAndAnswers,
  TitleSubtitleTextLink,
  TitleTextDocuments,
  TitleText,
}
