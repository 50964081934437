<template>
  <div>
    <app-section class="bg-blue-450 text-white w-full pb-86">
      <h1 class="title pt-32 text-46 font-semibold">{{ page.title }}</h1>
      <h2 class="subtitle pt-32 text-30">{{ page.subtitle }}</h2>
      <p class="description pt-32 text-20">{{ page.text }}</p>
    </app-section>

    <app-section>
      <component :is="component" class="px-71 py-80" :cards="page.children" />
    </app-section>
  </div>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

const COMPONENT_LANDINGS_ITEMS = {
  benefits: 'BenefitsItems',
  myData: 'UserDataItems',
  otherProducts: 'OtherProductsItems',
  socialWelfare: 'SocialItems',
}

export default {
  name: 'Landing',
  components: {
    AppSection: () => import('@/components/AppSection'),
    SocialItems: () => import('@/components/SocialItems'),
    BenefitsItems: () => import('@/components/BenefitsItems'),
    UserDataItems: () => import('@/components/UserDataItems'),
    OtherProductsItems: () => import('@/components/OtherProductsItems'),
  },
  mixins: [BaseTemplate],
  computed: {
    component() {
      let currentLanding
      for (const key in this.landingsId) {
        const landingId = this.landingsId[key]
        if (landingId === this.page.id) {
          currentLanding = key
        }
      }

      return COMPONENT_LANDINGS_ITEMS[currentLanding]
    },
  },
}
</script>
