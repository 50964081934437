<template>
  <app-section class="animated fadeIn">
    <h1 class="text-bbva text-46 font-bold leading-126 tracking-030 color-grey-900 mt-24 mb-40">
      {{ page.title }}
    </h1>

    <img v-if="page.image" :src="page.image.src" class="mb-64" />

    <p
      class="text-bbva text-21 font-light leading-127 whitespace-pre-wrap mb-99 color-grey-900"
      v-html="page.subtitle"
    />

    <a :href="page.linkUrl" target="_blank">
      <app-button class="bg-blue-350 mb-126 text-19 font-bold">
        {{ page.linkText }}
      </app-button>
    </a>
  </app-section>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'TitleSubtitleExternalLink',
  components: {
    AppButton: () => import('@/components/AppButton'),
    AppSection: () => import('@/components/AppSection'),
  },
  mixins: [BaseTemplate],
}
</script>
