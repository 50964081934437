import { mapState } from 'vuex'

export default {
  props: {
    id: {
      type: [Number, String],
    },
    page: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('portal', ['pages', 'landingsId']),
  },
}
