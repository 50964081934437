<template>
  <div class="home animated fadeIn">
    <carousel v-if="getCarousel" :slides="getCarousel.slides" />

    <app-section v-if="getUserData" class="bg-white">
      <user-data v-bind="getUserData" />
    </app-section>

    <app-section v-if="getBenefits" class="bg-blue-400">
      <benefits v-bind="getBenefits" />
    </app-section>

    <app-section v-if="getSocialWelfare" class="bg-blue-800">
      <social-welfare v-bind="getSocialWelfare" />
    </app-section>

    <app-section v-if="getOtherProducts" class="bg-grey-100">
      <other-products v-bind="getOtherProducts" />
    </app-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'Home',
  components: {
    AppSection: () => import('@/components/AppSection'),
    Carousel: () => import('./components/Carousel'),
    Benefits: () => import('./components/Benefits'),
    OtherProducts: () => import('./components/OtherProducts'),
    UserData: () => import('./components/UserData'),
    SocialWelfare: () => import('./components/SocialWelfare'),
  },
  mixins: [BaseTemplate],
  computed: {
    ...mapGetters('portal', [
      'getUserData',
      'getSocialWelfare',
      'getCarousel',
      'getBenefits',
      'getOtherProducts',
    ]),
  },
}
</script>
