<template>
  <app-section class="animated fadeIn">
    <h1 class="text-bbva text-46 font-bold leading-126 tracking-030 color-grey-900 my-32">
      {{ page.title }}
    </h1>

    <h2 class="text-30 font-medium leading-133 tracking-030 color-grey-900 mb-32">
      {{ page.subtitle }}
    </h2>

    <p
      class="text-bbva text-20 font-light leading-127 whitespace-pre-wrap mb-63"
      v-html="page.text"
    />

    <router-link :to="page.linkUrl.id.toString()">
      <app-button class="bg-blue-350 mb-126 text-19 font-bold">
        {{ page.linkText }}
      </app-button>
    </router-link>
  </app-section>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'TitleSubtitleTextLink',
  components: {
    AppButton: () => import('@/components/AppButton'),
    AppSection: () => import('@/components/AppSection'),
  },
  mixins: [BaseTemplate],
}
</script>
