<template>
  <app-section class="animated fadeIn">
    <div>
      <h1
        class="title font-bbva font-medium text-46 leading-126 tracking-030 color-grey-900 mt-31 mb-32"
      >
        {{ page.title }}
      </h1>
      <p class="font-bbva text-20 font-light leading-127 mb-82" v-html="page.text" />
    </div>
  </app-section>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'TitleText',
  components: {
    AppSection: () => import('@/components/AppSection'),
  },
  mixins: [BaseTemplate],
}
</script>
