<template>
  <div class="questions-answers-list">
    <question-answer
      v-for="(item, index) in items"
      :key="index"
      :question="item.question"
      :answer="item.answer"
    />
  </div>
</template>
<script>
import QuestionAnswer from '@/components/QuestionAnswer.vue'

export default {
  name: 'QuestionsAnswersList',
  components: {
    QuestionAnswer,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
}
</script>
