<template>
  <div class="dropdown animated fadeIn">
    <app-section>
      <div class="top-text-wrapper mb-70 mt-31">
        <h1 class="title text-bbva text-46 font-semibold leading-126 tracking-030 color-grey-900">
          {{ page.title }}
        </h1>

        <p
          class="text-20 font-medium leading-133 tracking-030 text-grey-500 pt-32"
          v-html="page.subtitle"
        />
      </div>
      <div class="middle-text-wrapper mb-24 mt-31">
        <h2 class="subtitle text-30 font-medium leading-133 tracking-030 text-grey-500 mb-22">
          {{ page.questionTitle }}
        </h2>

        <p class="text-21 mb-54 text-grey-500">{{ page.dropdownDescription }}</p>
      </div>
      <app-select
        v-if="page.options"
        class="select-component mb-102"
        placeholder="Selecciona el colectivo a consultar"
        :options="page.options"
        @select="selectOption"
      />

      <collectives-and-contributions-body v-if="optionSelected" :option="optionSelected" />
    </app-section>
  </div>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'Dropdown',
  components: {
    AppSelect: () => import('@/components/form/AppSelect'),
    AppSection: () => import('@/components/AppSection'),
    CollectivesAndContributionsBody: () => import('./components/CollectivesAndContributionsBody'),
  },
  mixins: [BaseTemplate],
  data: () => ({
    optionSelected: undefined,
  }),
  methods: {
    selectOption(item) {
      if (!item || !this.page.options) {
        return
      }
      this.optionSelected = this.page.options.find((option) => option.id === item.value)
    },
  },
}
</script>

<style lang="scss">
.dropdown {
  .select-component {
    max-width: 577px;
  }
}
</style>
