<template>
  <div class="question-answer my-64">
    <h2 class="text-24 title mb-19 font-bbva font-medium">{{ question }}</h2>
    <p class="text text-20">{{ answer }}</p>
  </div>
</template>
<script>
export default {
  name: 'QuestionAnswer',
  props: {
    question: {
      required: true,
      type: String,
    },
    answer: {
      required: true,
      type: String,
    },
  },
}
</script>
