<template>
  <div>
    <app-section class="animated fadeIn">
      <h1
        class="title font-bbva font-medium text-46 leading-126 tracking-030 color-grey-900 mt-31 mb-32"
      >
        {{ page.title }}
      </h1>
      <p class="font-bbva text-20 font-light leading-127 mb-82" v-html="page.text" />
    </app-section>
    <app-section class="bg-grey-100 documents mt-88 pt-104 pb-82">
      <documents-and-links-wrapper
        :documents="page.documents"
        :links="page.links"
        :document-link-text="$t('SHARED.INTERESTED')"
      />
    </app-section>
    <app-section class="columns pt-80 pb-119">
      <app-columns-card
        v-if="page.columns.hasColumns"
        :title="page.columns.title"
        :columns="page.columns.columns"
      />
    </app-section>
  </div>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'TextDocumentsColumns',

  components: {
    AppColumnsCard: () => import('@/components/AppColumnsCard'),
    AppSection: () => import('@/components/AppSection'),
    DocumentsAndLinksWrapper: () => import('@/components/DocumentsAndLinksWrapper.vue'),
  },

  mixins: [BaseTemplate],
}
</script>
