<template>
  <div>
    <component :is="page.component" v-if="page" :id="pageId" :page="page" />
  </div>
</template>

<script>
import Templates from '@/views/templates'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TemplateViewer',
  components: {
    ...Templates,
  },
  computed: {
    ...mapState('portal', ['landingsId', 'pages', 'headerPages']),
    ...mapGetters('portal', ['getLandingData']),
    pageId() {
      return this.$route.params.id
    },
    page() {
      const isLanding = this.pages[this.pageId] && this.pages[this.pageId].component === 'Landing'
      return isLanding ? this.getLandingData(this.pageId) : this.pages[this.pageId]
    },
  },
}
</script>
