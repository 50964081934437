<template>
  <div class="text-documents animated fadeIn">
    <app-section class="bg-white">
      <div class="information w-full">
        <h1 class="title pt-32 pb-72 text-46 font-semibold">{{ page.title }}</h1>
      </div>
    </app-section>
    <app-section class="bg-grey-100 pt-88 pb-63">
      <documents-with-title :title="page.block1.title" :documents="page.block1.documents" />
      <documents-with-title :title="page.block2.title" :documents="page.block2.documents" />
    </app-section>
  </div>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'

export default {
  name: 'TextsDocuments',
  components: {
    AppSection: () => import('@/components/AppSection.vue'),
    DocumentsWithTitle: () => import('./components/DocumentsWithTitle.vue'),
  },
  mixins: [BaseTemplate],
}
</script>
